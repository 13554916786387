<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      item-key="id"
      sort-by="marca"
      :search="search"
      mobile-breakpoint="0"
      calculate-widths
      class="elevation-4"
      :loading="loading_status"
      loader-height="10"
      loading-text="Cargando ..."
    >
      <template #top>
        <v-toolbar rounded flat>
          Movimientos de Inventario
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-toolbar rounded flat>
          <v-text-field
            v-model="dateFrom"
            label="Desde"
            hide-details="auto"
            required
            outlined
            type="date"
            :rules="f_required"
            dense
          />
          <v-text-field
            class="pl-2"
            v-model="dateTo"
            label="Hasta"
            hide-details="auto"
            required
            outlined
            type="date"
            :rules="f_required"
            dense
            append-outer-icon="mdi-magnify"
            @click:append-outer="get_report"
          />
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:[`item.costo`]="{ item }">
        <span>{{ parseFloat(item.costo).toLocaleString(2) }}</span>
      </template>
      <template v-slot:[`item.tcosto`]="{ item }">
        <span>{{
          "$" + (parseFloat(item.costo) * parseFloat(item.quantity)).toLocaleString(2)
        }}</span>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        <span>{{ "$" + parseFloat(item.price).toLocaleString(2) }}</span>
      </template>
      <template v-slot:[`item.tprecio`]="{ item }">
        <span>{{
          "$" + (parseFloat(item.price) * parseFloat(item.quantity)).toLocaleString(2)
        }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="mr-2" @click="showDoc(item)"> mdi-magnify </v-icon>
      </template>

      <template slot="body.append">
        <tr>
          <th>Total</th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th class="text-right">{{ sumTable("items", "quantity") }}</th>
          <th></th>
          <th class="text-right">{{ sumTable("items", "tcosto") }}</th>
          <th></th>
          <th class="text-right">{{ "$ " + sumTable("items", "tprecio") }}</th>
        </tr>
      </template>
    </v-data-table>
    <br />
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>Tipo de Eventos</v-card-title>
          <GChart type="PieChart" :data="chartEvents" :options="chartOptions" />
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>Categoría</v-card-title>
          <GChart type="PieChart" :data="chartCategories" :options="chartOptions" />
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="pa-6">
          <p>Proceso</p>
          <p>{{ this.lStatus }}</p>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver, getToday, getdays_ago } from "../services/webserver.js";
import { GChart } from "vue-google-charts";

import createDoc from "../utils/create_doc.js";
export default {
  components: { GChart },
  data() {
    return {
      search: "",
      lStatus: "",
      settings: null,
      loading_status: false,
      headers: [
        {
          text: "Fecha",
          align: "start",
          sortable: true,
          filterable: true,
          value: "order_date",
          dataType: "text",
        },
        {
          text: "Documento",
          align: "start",
          sortable: true,
          filterable: true,
          value: "order_number",
          dataType: "text",
        },
        {
          text: "Categoría",
          align: "start",
          sortable: true,
          filterable: true,
          value: "categoria",
          dataType: "text",
        },
        {
          text: "Grupo",
          align: "start",
          sortable: true,
          filterable: true,
          value: "grupo",
          dataType: "text",
        },
        {
          text: "Marca",
          align: "start",
          sortable: true,
          filterable: true,
          value: "marca",
          dataType: "text",
        },
        {
          text: "Referencia",
          align: "start",
          sortable: true,
          filterable: true,
          value: "refe",
          dataType: "text",
        },
        {
          text: "Color",
          align: "start",
          sortable: true,
          filterable: true,
          value: "color",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "end",
          sortable: true,
          filterable: true,
          value: "quantity",
          dataType: "text",
        },
        {
          text: "Costo Un",
          align: "end",
          sortable: true,
          filterable: true,
          value: "costo",
          dataType: "number",
        },
        {
          text: "Costo",
          align: "end",
          sortable: true,
          filterable: true,
          value: "tcosto",
          dataType: "number",
        },
        {
          text: "Precio Un",
          align: "end",
          sortable: true,
          filterable: true,
          value: "price",
          dataType: "number",
        },
        {
          text: "Precio",
          align: "end",
          sortable: true,
          filterable: true,
          value: "tprecio",
          dataType: "number",
        },
      ],
      items: [],
      dialog: false,
      item: createDoc(),
      dateFrom: getdays_ago(0),
      dateTo: getToday(),
      chartData: null,
      chartEvents: ["Evento", "Cantidad"],
      chartOptions: {
        curveType: "function",
        legend: { position: "right" },
        interpolateNulls: true,
        crosshair: { trigger: "both", opacity: 0.95, color: "#39FF14" },
        title: "",
        isStacked: false,
        pieHole: 0.4,
        hAxis: {
          title: "",
          slantedText: true,
          slantedTextAngle: 15,
        },
        colors: ["#1b9e77", "#d95f02", "#7570b3"],
        height: 400,
      },
      brand: null,
      brands: [],
      f_required: [(v) => !!v || "Requerido"],
      searchvalid: false,
      docId: null,
      docDate: null,
      orderItems: [],
      order_dialog: false,
      selected: [],
      opin: [],
      typeIn: null,
      docRefIn: null,
      validIn: false,
      SelectedCount: 0,
      error_form: false,
      errorOrder: false,
      timeout: 5000,
    };
  },

  mounted() {
    this.settings = window.settings;
  },
  methods: {
    showDoc(item) {
      this.selected = [];
      this.docId = item.order_id;
      this.docDate = item.order_date;
      this.$refs.submitbtn.$el.click();
      this.order_dialog = true;
    },
    sumTable(table, key) {
      return parseFloat(
        this[table].reduce((a, b) => a + (parseFloat(b[key]) || 0), 0)
      ).toLocaleString(2);
    },
    async get_report() {
      this.loading_status = true;
      var reports = [
        "OP",
        "OC",
        "AJ",
        "CP",
        "EI",
        "SI",
        "GI",
        "GS",
        "PS",
        "PE",
        "DV",
        "CD",
        "TB",
      ];
      this.items = [];
      var report = "";
      for (report of reports) {
        var qry = {
          store: window.store.store_id,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          events: report,
        };
        this.lStatus = "Consultando : " + report;
        this.loading_status = true;
        let promise = new Promise((resolve, reject) => {
          webserver(
            "get_events_day",
            qry,
            function (data) {
              resolve(data);
            },
            function () {
              reject([]);
            }
          );
        });
        let data = await promise;
        // var myElem = null;
        console.log(data);
        data.forEach((item) => {
          item.docType = report;
          // myElem = this.settings["CATEGORIA"].find((itm) => itm.codigo == item.categoria);
          // if (myElem) {
          //   item.category = this.settings["CATEGORIA"].find(
          //     (itm) => itm.codigo == item.categoria
          //   ).valor;
          // }

          // myElem = this.settings["GRUPO"].find((itm) => itm.codigo == item.grupo);
          // if (myElem) {
          //   item.group_name = this.settings["GRUPO"].find(
          //     (itm) => itm.codigo == item.grupo
          //   ).valor;
          // }

          // myElem = this.settings["MARCA"].find((itm) => itm.codigo == item.marca);
          // if (myElem) {
          //   item.brand = this.settings["MARCA"].find(
          //     (itm) => itm.codigo == item.marca
          //   ).valor;
          // }

          // myElem = this.settings["COLOR"].find((itm) => itm.codigo == item.color);
          // if (myElem) {
          //   item.color_name = this.settings["COLOR"].find(
          //     (itm) => itm.codigo == item.color
          //   ).valor;
          // }
          item.quantity = parseFloat(item.quantity);
        });
        this.items.push.apply(this.items, data);
        this.loading_status = false;

        var result = [];
        var dtSet = [];
        this.items.reduce(function (res, value) {
          if (!res[value.docType]) {
            res[value.docType] = { label: value.docType, value: 0 };
            result.push(res[value.docType]);
          }
          res[value.docType].value += parseFloat(value.quantity);
          return res;
        }, {});

        result.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));

        dtSet.push(["Evento", "Cantidad"]);
        result.forEach((rg) => {
          var item = [];
          item.push(rg.label);
          item.push(rg.value);
          dtSet.push(item);
        });

        this.chartEvents = dtSet;

        result = [];
        dtSet = [];

        this.items.reduce(function (res, value) {
          if (!res[value.category]) {
            res[value.category] = { label: value.category, value: 0 };
            result.push(res[value.category]);
          }
          res[value.category].value += parseFloat(value.quantity);
          return res;
        }, {});

        result.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));

        dtSet.push(["Categoría", "Cantidad"]);
        result.forEach((rg) => {
          var item = [];
          item.push(rg.label);
          item.push(rg.value);
          dtSet.push(item);
        });

        this.chartCategories = dtSet;
      }
    },
    GetSortOrder(prop) {
      return function (a, b) {
        if (a[prop] > b[prop]) {
          return 1;
        } else if (a[prop] < b[prop]) {
          return -1;
        }
        return 0;
      };
    },
    StockIn(e) {
      e.preventDefault();
      this.$refs.formIn.validate();
      if (this.validIn) {
        var qry = {};
        (qry.store = window.store.store_id), (qry.issueDate = getToday());
        (qry.user_id = window.profile.user_email),
          (qry.orderRef = this.typeIn + "-" + this.docRefIn);
        qry.data = this.selected;
        console.log(qry);
        this.loading_status = true;
        webserver("order_in", qry, (data) => {
          console.log(data);
          this.typeIn = null;
          this.docRefIn = null;
          this.loading_status = false;
          this.order_dialog = false;
          this.$refs.submitbtn.$el.click();
        });
      }
    },
  },
  watch: {
    selected: function () {
      this.SelectedCount = 0;
      this.selected.forEach((itm) => {
        this.SelectedCount += parseFloat(itm.quantity);
      });
    },
  },
};
</script>

<style>
.v-dialog {
  overflow-y: hidden !important;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"item-key":"id","sort-by":"marca","search":_vm.search,"mobile-breakpoint":"0","calculate-widths":"","loading":_vm.loading_status,"loader-height":"10","loading-text":"Cargando ..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"rounded":"","flat":""}},[_vm._v(" Movimientos de Inventario "),_c('v-spacer')],1),_c('v-toolbar',{attrs:{"rounded":"","flat":""}},[_c('v-text-field',{attrs:{"label":"Desde","hide-details":"auto","required":"","outlined":"","type":"date","rules":_vm.f_required,"dense":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}),_c('v-text-field',{staticClass:"pl-2",attrs:{"label":"Hasta","hide-details":"auto","required":"","outlined":"","type":"date","rules":_vm.f_required,"dense":"","append-outer-icon":"mdi-magnify"},on:{"click:append-outer":_vm.get_report},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.costo",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.costo).toLocaleString(2)))])]}},{key:"item.tcosto",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s("$" + (parseFloat(item.costo) * parseFloat(item.quantity)).toLocaleString(2)))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s("$" + parseFloat(item.price).toLocaleString(2)))])]}},{key:"item.tprecio",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s("$" + (parseFloat(item.price) * parseFloat(item.quantity)).toLocaleString(2)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.showDoc(item)}}},[_vm._v(" mdi-magnify ")])]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("Total")]),_c('th'),_c('th'),_c('th'),_c('th'),_c('th'),_c('th'),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.sumTable("items", "quantity")))]),_c('th'),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.sumTable("items", "tcosto")))]),_c('th'),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s("$ " + _vm.sumTable("items", "tprecio")))])])])],2),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v("Tipo de Eventos")]),_c('GChart',{attrs:{"type":"PieChart","data":_vm.chartEvents,"options":_vm.chartOptions}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v("Categoría")]),_c('GChart',{attrs:{"type":"PieChart","data":_vm.chartCategories,"options":_vm.chartOptions}})],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading_status),callback:function ($$v) {_vm.loading_status=$$v},expression:"loading_status"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',{staticClass:"pa-6"},[_c('p',[_vm._v("Proceso")]),_c('p',[_vm._v(_vm._s(this.lStatus))]),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }